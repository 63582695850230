import { Component, Input } from '@angular/core';
import { RoadmapService } from 'src/app/roadmap/service/roadmap.service';
import RoadmapItemInterface from './interface/roadmap-item.interface';

@Component({
  selector: 'zv-roadmap-item[level][isVisibleOnInit][status][text][gaEvent]',
  templateUrl: './roadmap-item.component.html',
  styleUrls: ['./roadmap-item.component.scss'],
})
export class RoadmapItemComponent {
  @Input() id!: RoadmapItemInterface['id'];
  @Input() level!: RoadmapItemInterface['level'];
  @Input() isVisibleOnInit!: RoadmapItemInterface['isVisibleOnInit'];
  @Input() status!: RoadmapItemInterface['status'];
  @Input() text!: RoadmapItemInterface['text'];
  @Input() textNote?: RoadmapItemInterface['textNote'];
  @Input() isUsedAsMock?: RoadmapItemInterface['isUsedAsMock'];
  @Input() linkTitle?: RoadmapItemInterface['linkTitle'];
  @Input() linkUrl?: RoadmapItemInterface['linkUrl'];
  @Input() gaEvent!: RoadmapItemInterface['gaEvent'];
  @Input() areChildrenVisible!: RoadmapItemInterface['areChildrenVisible'];
  @Input() children?: RoadmapItemInterface['children'];

  list: RoadmapItemInterface[] = [];

  constructor(protected roadmapService: RoadmapService) {}

  toggleChildrenOfItem(targetId: string): void {
    this.roadmapService.toggleChildrenOfItem(targetId);
  }
}
