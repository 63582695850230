import { Component } from '@angular/core';
import ConferenceItemInterface from 'src/app/conference/item/interface/conference-item.interface';
import mockedConferenceList from 'src/app/conference/list/data/conference-list.mock';
import RoadmapItemInterface from 'src/app/roadmap/item/interface/roadmap-item.interface';
import mockedRoadmapList from '../../roadmap/list/data/roadmap-list.mock';

@Component({
  selector: 'zv-page-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss'],
})
export class PageStyleGuideComponent {
  mockedRoadmapList: RoadmapItemInterface[] = mockedRoadmapList;
  mockedConferenceList: ConferenceItemInterface[] = mockedConferenceList;
}
