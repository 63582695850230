import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ImageLoadVerifierService } from 'src/app/image-load-verifier/service/image-load-verifier.service';

@Directive({
  selector: '[zvImageLoadVerifier]',
})
export class ImageLoadVerifierDirective implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    private imageLoadVerifierService: ImageLoadVerifierService
  ) {}

  ngOnInit(): void {
    this.imageLoadVerifierService.addImageToList(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.imageLoadVerifierService.removeImageFromList(
      this.elementRef.nativeElement
    );
  }

  @HostListener('load')
  onLoad(): void {
    this.imageLoadVerifierService.removeImageFromList(
      this.elementRef.nativeElement
    );
  }
}
