import { Component, Input } from '@angular/core';
import ConferenceItemInterface from './interface/conference-item.interface';

@Component({
  selector:
    'zv-conference-item[name][organizer][location][icon][role][isUsedAsMock][direction]',
  templateUrl: './conference-item.component.html',
  styleUrls: ['./conference-item.component.scss'],
})
export class ConferenceItemComponent {
  @Input() name!: ConferenceItemInterface['name'];
  @Input() nameLinkTitle?: ConferenceItemInterface['nameLinkTitle'];
  @Input() nameLinkUrl?: ConferenceItemInterface['nameLinkTitle'];
  @Input() organizer!: ConferenceItemInterface['organizer'];
  @Input() organizerLinkTitle?: ConferenceItemInterface['organizerLinkTitle'];
  @Input() organizerLinkUrl?: ConferenceItemInterface['organizerLinkTitle'];
  @Input() location!: ConferenceItemInterface['location'];
  @Input() icon!: ConferenceItemInterface['icon'];
  @Input() role!: ConferenceItemInterface['name'];
  @Input() presentationTitle?: ConferenceItemInterface['presentationTitle'];
  @Input() note?: ConferenceItemInterface['note'];
  @Input() isUsedAsMock!: ConferenceItemInterface['isUsedAsMock'];
  @Input() direction!: ConferenceItemInterface['direction'];
}
