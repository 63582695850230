import ConferenceItemInterface from '../../item/interface/conference-item.interface';

const conferenceList: ConferenceItemInterface[] = [
  {
    name: 'Vue.js Global Online 2020',
    nameLinkTitle: 'Vue.js Global Online 2020',
    nameLinkUrl: 'https://2020.vuejsglobal.com/',
    organizer: 'Frontend Love',
    organizerLinkTitle: 'Frontend Love',
    organizerLinkUrl: 'https://jsworldconference.com/',
    location: 'Joined online from Amsterdam, Netherlands',
    icon: 'featherMonitor',
    role: 'attendee',
    presentationTitle: null,
    note: null,
    isUsedAsMock: false,
    direction: 'left',
  },
  {
    name: 'React Live 2022',
    nameLinkTitle: 'React Live 2022',
    nameLinkUrl: 'https://www.reactlive.nl/',
    organizer: 'Passionate people',
    organizerLinkTitle: 'Passionate people',
    organizerLinkUrl: 'https://passionatepeople.io/',
    location: 'Flew to Amsterdam, Netherlands',
    icon: 'featherSend',
    role: 'attendee',
    presentationTitle: null,
    note: null,
    isUsedAsMock: false,
    direction: 'right',
  },
  {
    name: 'Engineering days event 2022',
    nameLinkTitle: null,
    nameLinkUrl: null,
    organizer: 'Endava',
    organizerLinkTitle: 'Endava',
    organizerLinkUrl: 'https://www.endava.com/',
    location: 'Flew to Bucharest, Romania',
    icon: 'featherSend',
    role: 'speaker',
    presentationTitle: "Presenting Endava's vision of unified commerce",
    note: null,
    isUsedAsMock: false,
    direction: 'left',
  },
  {
    name: 'Ng Poland 2022',
    nameLinkTitle: 'Ng Poland 2022',
    nameLinkUrl: 'https://www.ng-poland.pl/',
    organizer: 'Dariusz Kalbarczyk',
    organizerLinkTitle: 'Dariusz Kalbarczyk',
    organizerLinkUrl:
      'https://www.linkedin.com/in/ngkalbarczyk/?originalSubdomain=pl',
    location: 'Joined online from Warsaw, Poland',
    icon: 'featherMonitor',
    role: 'attendee',
    presentationTitle: null,
    note: null,
    isUsedAsMock: false,
    direction: 'right',
  },
  {
    name: 'Development week event 2022',
    nameLinkTitle: null,
    nameLinkUrl: null,
    organizer: 'Endava',
    organizerLinkTitle: 'Endava',
    organizerLinkUrl: 'https://www.endava.com/',
    location: 'Joined online from Ljubljana, Slovenia',
    icon: 'featherMonitor',
    role: 'speaker',
    presentationTitle: "Presenting Endava's vision of unified commerce",
    note: null,
    isUsedAsMock: false,
    direction: 'left',
  },
];

export default conferenceList;
