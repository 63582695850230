<div class="roadmap-item level-{{ level }}" data-test="roadmap-item-level">
  <!-- Text -->
  <span
    class="text"
    data-test="roadmap-item-text"
    [ngClass]="{
      gradient: status === 'knowledgeNeedToGain',
      solid: status === 'knowledgeAbsorbed'
    }"
    *ngIf="!linkTitle && !linkUrl && !children"
  >
    {{ text }}
    <span
      class="note"
      data-test="roadmap-item-note"
      *ngIf="status === 'knowledgeNeedToGain'"
    >
      {{ isUsedAsMock ? 'Lorem ipsum' : 'To learn' }}
    </span>
  </span>
  <!-- / Text -->
  <!-- Button -->
  <button
    class="button"
    data-test="roadmap-item-button"
    [ngClass]="{
      gradient: status === 'knowledgeNeedToGain',
      solid: status === 'knowledgeAbsorbed'
    }"
    *ngIf="!linkTitle && !linkUrl && children && children.length > 0"
    (click)="toggleChildrenOfItem(id)"
  >
    <span>{{ text }} </span>
    <ng-icon
      class="badge"
      [name]="areChildrenVisible ? 'featherChevronUp' : 'featherChevronDown'"
      strokeWidth="1"
      size="24"
      [ngStyle]="{
        transform: 'translateX(5px) translateY(0px)'
      }"
    ></ng-icon>
    <span class="note" data-test="roadmap-item-note" *ngIf="textNote">{{
      textNote
    }}</span>
  </button>
  <!-- / Button -->
  <!-- Link -->
  <a
    class="link"
    data-test="roadmap-item-link"
    [ngClass]="{
      gradient: status === 'knowledgeNeedToGain',
      solid: status === 'knowledgeAbsorbed'
    }"
    *ngIf="linkTitle && linkUrl"
    [title]="linkTitle"
    [href]="linkUrl"
    rel="noopener noreferrer"
    target="_blank"
  >
    {{ linkTitle | ignoreLastWord }}
    <span class="whitespace-nowrap">
      {{ linkTitle | takeLastWord
      }}<ng-icon
        name="featherExternalLink"
        strokeWidth="1"
        [ngStyle]="{
          transform: 'translateX(2px) translateY(2px)'
        }"
      ></ng-icon
    ></span>
    <span
      class="note"
      data-test="roadmap-item-note"
      *ngIf="textNote || status === 'knowledgeNeedToGain'"
    >
      <span *ngIf="textNote">{{ textNote }}</span
      ><span *ngIf="status === 'knowledgeNeedToGain'">{{
        isUsedAsMock ? 'Lorem ipsum' : 'To learn'
      }}</span>
    </span>
  </a>
  <!-- / Link -->
</div>
