<!-- TODO: loop (probably recursive) -->

<!-- Level 1 -->
<ul class="roadmap-item-list list-level-1">
  <li
    class="list-item-level-1"
    [ngClass]="{ 'list-item-level-1-last': isLast }"
    *ngFor="let item of list; let isLast = last"
  >
    <zv-roadmap-item
      [id]="item.id"
      [level]="item.level"
      [isVisibleOnInit]="item.isVisibleOnInit"
      [status]="item.status"
      [text]="item.text"
      [textNote]="item.textNote"
      [isUsedAsMock]="item.isUsedAsMock"
      [linkTitle]="item.linkTitle"
      [linkUrl]="item.linkUrl"
      [gaEvent]="item.gaEvent"
      [areChildrenVisible]="item.areChildrenVisible"
      [children]="item.children"
    ></zv-roadmap-item>
    <!-- Level 2 -->
    <ul
      class="roadmap-item-list list-level-2"
      [ngClass]="{ 'list-level-2-last': isLast }"
      *ngIf="item.areChildrenVisible"
    >
      <li
        class="list-item-level-2"
        [ngClass]="{ 'list-item-level-2-last': isLast }"
        *ngFor="let item of item.children; let isLast = last"
      >
        <zv-roadmap-item
          [id]="item.id"
          [level]="item.level"
          [isVisibleOnInit]="item.isVisibleOnInit"
          [status]="item.status"
          [text]="item.text"
          [textNote]="item.textNote"
          [isUsedAsMock]="item.isUsedAsMock"
          [linkTitle]="item.linkTitle"
          [linkUrl]="item.linkUrl"
          [gaEvent]="item.gaEvent"
          [areChildrenVisible]="item.areChildrenVisible"
          [children]="item.children"
        ></zv-roadmap-item>
        <!-- Level 3 -->
        <ul
          class="roadmap-item-list list-level-3"
          [ngClass]="{ 'list-level-3-last': isLast }"
          *ngIf="item.areChildrenVisible"
        >
          <li
            class="list-item-level-3"
            [ngClass]="{ 'list-item-level-3-last': isLast }"
            *ngFor="let item of item.children; let isLast = last"
          >
            <zv-roadmap-item
              [id]="item.id"
              [level]="item.level"
              [isVisibleOnInit]="item.isVisibleOnInit"
              [status]="item.status"
              [text]="item.text"
              [textNote]="item.textNote"
              [isUsedAsMock]="item.isUsedAsMock"
              [linkTitle]="item.linkTitle"
              [linkUrl]="item.linkUrl"
              [gaEvent]="item.gaEvent"
              [areChildrenVisible]="item.areChildrenVisible"
              [children]="item.children"
            ></zv-roadmap-item>
            <!-- Level 4 -->
            <ul
              class="roadmap-item-list list-level-4"
              [ngClass]="{ 'list-level-4-last': isLast }"
              *ngIf="item.areChildrenVisible"
            >
              <li
                class="list-item-level-4"
                [ngClass]="{ 'list-item-level-4-last': isLast }"
                *ngFor="let item of item.children; let isLast = last"
              >
                <zv-roadmap-item
                  [id]="item.id"
                  [level]="item.level"
                  [isVisibleOnInit]="item.isVisibleOnInit"
                  [status]="item.status"
                  [text]="item.text"
                  [textNote]="item.textNote"
                  [isUsedAsMock]="item.isUsedAsMock"
                  [linkTitle]="item.linkTitle"
                  [linkUrl]="item.linkUrl"
                  [gaEvent]="item.gaEvent"
                  [areChildrenVisible]="item.areChildrenVisible"
                  [children]="item.children"
                ></zv-roadmap-item>
                <!-- Level 5 -->
                <ul
                  class="roadmap-item-list list-level-5"
                  [ngClass]="{ 'list-level-5-last': isLast }"
                  *ngIf="item.areChildrenVisible"
                >
                  <li
                    class="list-item-level-5"
                    [ngClass]="{ 'list-item-level-5-last': isLast }"
                    *ngFor="let item of item.children; let isLast = last"
                  >
                    <zv-roadmap-item
                      [id]="item.id"
                      [level]="item.level"
                      [isVisibleOnInit]="item.isVisibleOnInit"
                      [status]="item.status"
                      [text]="item.text"
                      [textNote]="item.textNote"
                      [isUsedAsMock]="item.isUsedAsMock"
                      [linkTitle]="item.linkTitle"
                      [linkUrl]="item.linkUrl"
                      [gaEvent]="item.gaEvent"
                      [areChildrenVisible]="item.areChildrenVisible"
                      [children]="item.children"
                    ></zv-roadmap-item>
                    <!-- Level 6 -->
                    <ul
                      class="roadmap-item-list list-level-6"
                      [ngClass]="{ 'list-level-6-last': isLast }"
                      *ngIf="item.areChildrenVisible"
                    >
                      <li
                        class="list-item-level-6"
                        [ngClass]="{ 'list-item-level-6-last': isLast }"
                        *ngFor="let item of item.children; let isLast = last"
                      >
                        <zv-roadmap-item
                          [id]="item.id"
                          [level]="item.level"
                          [isVisibleOnInit]="item.isVisibleOnInit"
                          [status]="item.status"
                          [text]="item.text"
                          [textNote]="item.textNote"
                          [isUsedAsMock]="item.isUsedAsMock"
                          [linkTitle]="item.linkTitle"
                          [linkUrl]="item.linkUrl"
                          [gaEvent]="item.gaEvent"
                          [areChildrenVisible]="item.areChildrenVisible"
                          [children]="item.children"
                        ></zv-roadmap-item>
                      </li>
                    </ul>
                    <!-- / Level 6 -->
                  </li>
                </ul>
                <!-- / Level 5 -->
              </li>
            </ul>
            <!-- / Level 4 -->
          </li>
        </ul>
        <!-- / Level 3 -->
      </li>
    </ul>
    <!-- / Level 2 -->
  </li>
</ul>
<!-- / Level 1 -->
