import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormFieldInputInterface } from 'src/app/form/interface/form.interface';

@Component({
  selector: 'zv-input[id][type][label][control]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() id!: FormFieldInputInterface['id'];
  @Input() type!: FormFieldInputInterface['type'];
  @Input() label!: FormFieldInputInterface['label'];
  @Input() control!: FormFieldInputInterface['control'];

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
