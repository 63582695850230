import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import RoadmapItemInterface from '../item/interface/roadmap-item.interface';

@Injectable({
  providedIn: 'root',
})
export class RoadmapService {
  private list$$: BehaviorSubject<RoadmapItemInterface[]> = new BehaviorSubject<
    RoadmapItemInterface[]
  >([]);

  constructor(private http: HttpClient) {
    this.fetchList$().subscribe();
  }

  private fetchList$(): Observable<RoadmapItemInterface[]> {
    return this.http
      .get<RoadmapItemInterface[]>(`${environment.apiHost}/roadmap-list`)
      .pipe(
        tap((list: RoadmapItemInterface[]) => {
          this.list$$.next(list);
        })
      );
  }

  getList$(): Observable<RoadmapItemInterface[]> {
    return this.list$$.asObservable();
  }

  toggleAllItems(operation: 'collapse' | 'expand') {
    const currentList = this.list$$.getValue();
    const updatedList = this.toggleAllItemsAndReturnUpdatedList(
      currentList,
      operation
    );

    this.list$$.next(updatedList);
  }

  toggleChildrenOfItem(targetId: string) {
    const currentList = this.list$$.getValue();
    const updatedList = this.toggleChildrenOfItemAndReturnUpdatedList(
      currentList,
      targetId
    );

    this.list$$.next(updatedList);
  }

  private toggleAllItemsAndReturnUpdatedList(
    list: RoadmapItemInterface[],
    operation: 'collapse' | 'expand'
  ): RoadmapItemInterface[] {
    return list.map((item: RoadmapItemInterface) => {
      const updatedItem: RoadmapItemInterface = { ...item };
      if (item.children) {
        if (operation === 'collapse') {
          updatedItem.areChildrenVisible = false;
        } else if (operation === 'expand') {
          updatedItem.areChildrenVisible = true;
        }

        // Recursively go deeper
        updatedItem.children = this.toggleAllItemsAndReturnUpdatedList(
          item.children,
          operation
        );
      }
      return updatedItem;
    });
  }

  private toggleChildrenOfItemAndReturnUpdatedList(
    list: RoadmapItemInterface[],
    targetId: string
  ): RoadmapItemInterface[] {
    return list.map((item) => {
      if (item.id === targetId && item.areChildrenVisible != null) {
        return {
          ...item,
          areChildrenVisible: !item.areChildrenVisible,
        };
      }

      if (item.children) {
        // Recursively go deeper
        const updatedChildren = this.toggleChildrenOfItemAndReturnUpdatedList(
          item.children,
          targetId
        );
        return { ...item, children: updatedChildren };
      }

      return { ...item };
    });
  }
}
