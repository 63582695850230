<div class="lg:flex lg:flex-row">
  <div class="lg:basis-2/3">
    <pre>Style guide<br />(though, a very short one)</pre>
    <pre><hr></pre>
    <pre>Headings</pre>
    <h1>Lorem, ipsum dolor sit amet (h1)</h1>
    <h2>Lorem, ipsum dolor sit amet (h2)</h2>

    <pre>Colors</pre>
    <div class="color-list">
      <div class="bg-white-500"><span>500</span></div>
      <div class="bg-white-600"><span>600</span></div>
    </div>
    <div class="color-list">
      <div class="bg-cyan-500"><span>500</span></div>
    </div>
    <div class="color-list">
      <div class="bg-pink-100"><span>100</span></div>
      <div class="bg-pink-500"><span>500</span></div>
      <div class="bg-pink-900"><span>900</span></div>
      <div class="bg-pink-1200"><span>1200</span></div>
    </div>
    <div class="color-list">
      <div class="bg-blue-500 outline"><span>500</span></div>
    </div>

    <pre>Paragraph</pre>
    <p>
      Ratione maiores magni odit, quia aut consequatur ratione maiores eaque?
    </p>
    <p>
      Lorem ipsum <a title="tenetur" href="#">tenetur</a> dolor sit amet
      consectetur,
      <zv-external-link size="p" linkTitle="adipisicing elit" linkUrl="#"
        >adipisicing elit</zv-external-link
      >. Aliquam similique nihil, quae ad aspernatur.<br />
      Minus officia ipsum ratione maiores magni odit, quia aut consequatur
      eaque? Aliquam.
    </p>

    <pre>Small</pre>
    <small>
      Ratione maiores magni odit, quia aut consequatur ratione maiores eaque? </small
    ><br />
    <small>
      Lorem ipsum <a title="tenetur" href="#">tenetur</a> dolor sit amet
      consectetur,
      <zv-external-link size="small" linkTitle="adipisicing elit" linkUrl="#"
        >adipisicing elit</zv-external-link
      >. Aliquam similique nihil, quae ad aspernatur. Ratione maiores magni
      odit, quia aut.<br />
      Minus officia ipsum ratione maiores magni odit, quia aut consequatur
      eaque? Aliquam.
    </small>

    <pre>Button</pre>
    <button style="width: 200px">Lorem ipsum</button>

    <pre>Stat list</pre>
    <zv-stat-list></zv-stat-list>

    <pre>Certificate</pre>
    <zv-certificate
      linkUrl="https://zigavukcevic.dev"
      linkTitle="Lorem ipsum"
      title="Lorem ipsum"
      type="illo eaque"
      issuedBy="ratione"
      >Lorem ipsum</zv-certificate
    >

    <pre>Roadmap item list</pre>
    <zv-roadmap-list [list]="mockedRoadmapList"></zv-roadmap-list>

    <pre>Loading indicator</pre>
    <zv-loading-indicator></zv-loading-indicator>

    <pre>Conference</pre>
    <zv-conference-list
      [list]="mockedConferenceList"
      text="Dolor ipsum?"
    ></zv-conference-list>
  </div>
</div>
