import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'zv-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  contactForm = new FormGroup(
    {
      fullName: new FormControl<string>('', [Validators.required]),
      emailAddress: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      subject: new FormControl<string>('', [Validators.required]),
      message: new FormControl<string>('', [Validators.required]),
    },
    { updateOn: 'blur' }
  );

  onSubmit(): void {}
}
