import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { debounceTime, Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[zvObserveIntersection]',
  exportAs: 'observeIntersection',
})
export class ObserveIntersectionDirective implements OnDestroy {
  @Input() root: HTMLElement | null = null;
  @Input() rootMargin = '0px 0px 0px 0px';
  @Input() threshold = 0.75;
  @Input() debounceTime = 250;

  isIntersecting = false;
  private _subscription: Subscription = this._createAndObserve();

  constructor(private elementRef: ElementRef) {}

  private _createAndObserve(): Subscription {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    return new Observable<boolean>((subscriber) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];
        subscriber.next(isIntersecting);

        if (isIntersecting) {
          intersectionObserver.disconnect();
        }
      }, options);

      intersectionObserver.observe(this.elementRef.nativeElement);

      return {
        unsubscribe(): void {
          intersectionObserver.disconnect();
        },
      };
    })
      .pipe(debounceTime(this.debounceTime))
      .subscribe((status): void => {
        this.isIntersecting = status;
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
