import { Component, OnInit } from '@angular/core';
import { RoadmapService } from 'src/app/roadmap/service/roadmap.service';
import certificateList from '../../certificate/data/certificate-list';
import CertificateInterface from '../../certificate/interface/certificate.interface';
import ConferenceItemInterface from '../../conference/item/interface/conference-item.interface';
import conferenceList from '../../conference/list/data/conference-list';
import RoadmapItemInterface from '../../roadmap/item/interface/roadmap-item.interface';

@Component({
  selector: 'zv-page-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class PageHomeComponent implements OnInit {
  isRoadmapExpanded: boolean = false;
  roadmapList: RoadmapItemInterface[] = [];
  certificateList: CertificateInterface[] = certificateList;
  conferenceList: ConferenceItemInterface[] = conferenceList;

  constructor(protected roadmapService: RoadmapService) {
    const consoleGreeting = {
      text: "Hey, fellow developer! Let's do some inspection together! 👍",
      styles: [
        'padding: 7px 9px 7px 9px',
        'background-color: #DC2625',
        'color: white',
        'border: 5px solid yellow',
        'font-size: 13px',
        'font-family: monospace',
      ],
    };

    // eslint-disable-next-line no-console
    console.info(`%c${consoleGreeting.text}`, consoleGreeting.styles.join(';'));
  }

  ngOnInit(): void {
    this.roadmapService.getList$().subscribe((list) => {
      this.roadmapList = list;
    });
  }

  expandRoadmapList(): void {
    this.roadmapService.toggleAllItems('expand');
    this.isRoadmapExpanded = true;
  }

  collapseRoadmapList(): void {
    this.roadmapService.toggleAllItems('collapse');
    this.isRoadmapExpanded = false;
  }
}
