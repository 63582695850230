import { Component, Input, OnInit } from '@angular/core';
import ExternalLinkValueListInterface from './interface/external-link-value-list.interface';

@Component({
  selector: 'zv-external-link[size][linkUrl][linkTitle]',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
})
export class ExternalLinkComponent implements OnInit {
  @Input() size!: 'small' | 'p';
  @Input() linkUrl!: string;
  @Input() linkTitle!: string;

  mappedValueList!: ExternalLinkValueListInterface;

  ngOnInit(): void {
    this.mappedValueList = this.mapValueList(this.size);
  }

  mapValueList(size: string): any {
    const valueList: { [key: string]: ExternalLinkValueListInterface } = {
      small: {
        translateY: 2,
        marginLeft: 2,
      },
      p: {
        translateY: 2,
        marginLeft: 2,
      },
    };

    return {
      translateY: valueList[size].translateY.toString(),
      marginLeft: valueList[size].marginLeft.toString(),
    };
  }
}
