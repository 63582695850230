import { Component, Input } from '@angular/core';
import ConferenceItemInterface from '../item/interface/conference-item.interface';

@Component({
  selector: 'zv-conference-list[list][text]',
  templateUrl: './conference-list.component.html',
  styleUrls: ['./conference-list.component.scss'],
})
export class ConferenceListComponent {
  @Input() list!: ConferenceItemInterface[];
  @Input() text!: string;
}
