<div class="conference-list">
  <ul>
    <li
      *ngFor="let item of list; let index = index"
      [ngClass]="index % 2 === 0 ? 'left' : 'right'"
    >
      <zv-conference-item
        [name]="item.name"
        [nameLinkTitle]="item.nameLinkTitle"
        [nameLinkUrl]="item.nameLinkUrl"
        [organizer]="item.organizer"
        [organizerLinkTitle]="item.organizerLinkTitle"
        [organizerLinkUrl]="item.organizerLinkUrl"
        [location]="item.location"
        [icon]="item.icon"
        [role]="item.role"
        [presentationTitle]="item.presentationTitle"
        [note]="item.note"
        [isUsedAsMock]="item.isUsedAsMock"
        [direction]="item.direction"
      ></zv-conference-item>
    </li>
  </ul>
  <p>{{ text }}</p>
</div>
