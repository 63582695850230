<a
  rel="noopener noreferrer"
  target="_blank"
  [href]="linkUrl"
  [title]="linkTitle"
>
  {{ linkTitle | ignoreLastWord }}
  <span class="whitespace-nowrap">
    {{ linkTitle | takeLastWord
    }}<ng-icon
      name="featherExternalLink"
      strokeWidth="1"
      [ngStyle]="{
        transform: 'translateY(' + mappedValueList.translateY + 'px)',
        marginLeft: mappedValueList.marginLeft + 'px'
      }"
    ></ng-icon
  ></span>
</a>
