<ul class="stat-list">
  <!-- Period web -->
  <li>
    <strong class="stat-list-amount" data-test="stat-list-period-web">{{
      periodWeb
    }}</strong
    ><span>years in the web industry</span>
  </li>
  <!-- / Period web -->
  <!-- Period programming -->
  <li>
    <strong class="stat-list-amount" data-test="stat-list-period-programming">{{
      periodProgramming
    }}</strong
    ><span>years of active programming</span>
  </li>
  <!-- / Period programming -->
  <!-- Number of cups -->
  <li data-test="stat-list-number-of-cups-container" *ngIf="numberOfCups">
    <strong class="stat-list-amount" data-test="stat-list-number-of-cups">
      {{ numberOfCups }}
    </strong>
    <span data-test="stat-list-number-of-cups-text" [ngPlural]="numberOfCups">
      <ng-template ngPluralCase="=1">cup</ng-template>
      <ng-template ngPluralCase="other">cups</ng-template>
      of coffee drank today
    </span>
  </li>
  <!-- / Number of cups -->
</ul>
