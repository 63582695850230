import CertificateInterface from '../interface/certificate.interface';

const certificateList: CertificateInterface[] = [
  {
    // Link to certificate:
    // https://www.hackerrank.com/certificates/d390638bc186
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2022-08-17-angular-by-hacker-rank-bab0f7b22e7b3020.webp',
    linkTitle: 'Angular certificate by HackerRank',
    title: 'Angular',
    type: 'code challenge',
    issuedBy: 'HackerRank',
  },
  {
    // Link to certificate:
    // https://www.udemy.com/certificate/UC-8774807a-0a09-4296-b7e2-47a3c44b4427/
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2022-09-11-rxjs-in-practice-by-udemy-c7403a2dd3b55be9.webp',
    linkTitle: 'RxJs in practice certificate by Udemy',
    title: 'RxJs in practice',
    type: 'course',
    issuedBy: 'Udemy',
  },
  {
    // Link to certificate:
    // https://www.hackerrank.com/certificates/ed468fd85b49
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2022-08-10-javascript-by-hacker-rank-de9d80fc6b58624a.webp',
    linkTitle: 'JavaScript certificate by HackerRank',
    title: 'JavaScript',
    type: 'code challenge',
    issuedBy: 'HackerRank',
  },
  {
    // Link to certificate:
    // https://www.udemy.com/certificate/UC-c9fdbfcd-1e38-43a2-8747-860f9d50f5ba/
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2023-09-13-cypress-by-udemy-6c031b153a00c80b.webp',
    linkTitle: 'Cypress - from zero to expert certificate by Udemy',
    title: 'Cypress',
    type: 'course',
    issuedBy: 'Udemy',
  },
  {
    // Link to certificate:
    // https://www.credly.com/badges/bd05687f-4cab-485e-b9cd-dfa8fc74d16b/linked_in_profile
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2022-11-24-technical-trainer-by-endava-9ff324ef18383cdb.webp',
    linkTitle: 'Technical trainer by Endava',
    title: 'Technical trainer',
    type: 'involvement',
    issuedBy: 'Endava',
  },
  {
    // Link to certificate: not available public
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2023-09-14-nest-js-by-nest-js-ee80fb32dfcca8f0.webp',
    linkTitle: 'NestJS fundamentals by NestJS',
    title: 'NestJS fundamentals',
    type: 'course',
    issuedBy: 'NestJS',
  },
  {
    // Link to certificate:
    // https://www.linkedin.com/learning/certificates/4ccd39662e8804c7abcdcdb54251f519defe50995ea1e61302304e4b46049c40?trk=share_certificate
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2022-08-15-sass-by-linkedin-5bdca192b15598e2.webp',
    linkTitle: 'SASS certificate by LinkedIn',
    title: 'SASS',
    type: 'course',
    issuedBy: 'LinkedIn',
  },
  {
    // Link to certificate:
    // https://www.hackerrank.com/certificates/6141392cc6cf
    linkUrl:
      '/assets/certificates/ziga-vukcevic-certificate-2022-08-10-css-by-hacker-rank-94eeb78ba1067e32.webp',
    linkTitle: 'CSS certificate by HackerRank',
    title: 'CSS',
    type: 'questionnaire',
    issuedBy: 'HackerRank',
  },
];

export default certificateList;
