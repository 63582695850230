import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'takeLastWord',
})
export class TakeLastWordPipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    }

    const words = input.trim().split(' ');

    return words.pop() || '';
  }
}
