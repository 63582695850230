import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageLoadVerifierService {
  private loadingImages: HTMLElement[] = [];
  private areImagesLoaded$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  areImagesLoaded$ = this.areImagesLoaded$$.asObservable();

  addImageToList(image: HTMLElement) {
    this.loadingImages.push(image);
  }

  removeImageFromList(loadedImage: HTMLElement) {
    this.loadingImages = this.loadingImages.filter((image: HTMLElement) => {
      return loadedImage !== image;
    });

    if (this.loadingImages.length === 0) {
      this.areImagesLoaded$$.next(true);
    }
  }
}
