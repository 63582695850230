import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageLoadVerifierService } from 'src/app/image-load-verifier/service/image-load-verifier.service';
import packageJSON from '../../package.json';
import coverageSummary from '../reports/coverage-summary.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  areImagesLoaded$: Observable<boolean> =
    this.imageLoadVerifierService.areImagesLoaded$;
  unitTestsTotalCodeCoverage: number = Math.round(
    coverageSummary.total.lines.pct
  );
  appVersion: string = packageJSON.version;

  constructor(private imageLoadVerifierService: ImageLoadVerifierService) {}
}
