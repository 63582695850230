import { Component, Input } from '@angular/core';
import CertificateInterface from './interface/certificate.interface';

@Component({
  selector: 'zv-certificate[linkUrl][linkTitle][title][type][issuedBy]',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent {
  @Input() linkUrl!: CertificateInterface['linkUrl'];
  @Input() linkTitle!: CertificateInterface['linkTitle'];
  @Input() title!: CertificateInterface['title'];
  @Input() type!: CertificateInterface['type'];
  @Input() issuedBy!: CertificateInterface['issuedBy'];

  openImageInNewWindow(): void {
    window.open(this.linkUrl, '_blank');
  }
}
