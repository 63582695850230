import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ignoreLastWord',
})
export class IgnoreLastWordPipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    }

    const words = input.trim().split(' ');
    words.pop();

    return words.join(' ');
  }
}
