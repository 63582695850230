import RoadmapItemInterface from '../../item/interface/roadmap-item.interface';

const mockedRoadmapList: RoadmapItemInterface[] = [
  {
    id: '38531bf9-80b1-4a31-a4fb-6b730c06a302',
    level: 1,
    isVisibleOnInit: true,
    status: 'knowledgeAbsorbed',
    text: 'Lorem ipsum',
    textNote: null,
    isUsedAsMock: true,
    linkTitle: null,
    linkUrl: null,
    gaEvent: '',
    areChildrenVisible: true,
    children: [
      {
        id: 'f167f176-da8d-4f52-949e-ada914b5d6b1',
        level: 2,
        isVisibleOnInit: true,
        status: 'knowledgeAbsorbed',
        text: 'Lorem ipsum dolor ipsum dolor',
        textNote: 'lorem ipsum',
        isUsedAsMock: true,
        linkTitle: null,
        linkUrl: null,
        gaEvent: '',
        areChildrenVisible: false,
        children: [
          {
            id: '213ae993-5b1f-400b-b0de-1e6d43a6d881',
            level: 3,
            isVisibleOnInit: false,
            status: 'knowledgeAbsorbed',
            text: 'Lorem ipsum dolor',
            textNote: null,
            isUsedAsMock: true,
            linkTitle: 'Lorem ipsum dolor',
            linkUrl: '#',
            gaEvent: '',
            areChildrenVisible: null,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 'e278fc5f-4c3d-4e63-a3b0-e4ab676a0ae5',
    level: 1,
    isVisibleOnInit: true,
    status: 'knowledgeAbsorbed',
    text: 'Lorem ipsum dolor ipsum dolor',
    textNote: 'Lorem ipsum',
    isUsedAsMock: true,
    linkTitle: null,
    linkUrl: null,
    gaEvent: '',
    areChildrenVisible: true,
    children: [
      {
        id: '32c07ccd-17f7-4e5e-a70b-60ffef35b7ff',
        level: 2,
        isVisibleOnInit: true,
        status: 'knowledgeNeedToGain',
        text: 'Lorem ipsum dolor',
        textNote: null,
        isUsedAsMock: true,
        linkTitle: null,
        linkUrl: null,
        gaEvent: '',
        areChildrenVisible: false,
        children: null,
      },
    ],
  },
];

export default mockedRoadmapList;
