import { Component, Input } from '@angular/core';
import RoadmapItemInterface from '../item/interface/roadmap-item.interface';

@Component({
  selector: 'zv-roadmap-list[list]',
  templateUrl: './roadmap-list.component.html',
  styleUrls: ['./roadmap-list.component.scss'],
})
export class RoadmapListComponent {
  @Input() list!: RoadmapItemInterface[];
}
