<h2
  class="text-center relative"
  [class.reduced-pt]="hasReducedPaddingTop"
  [class.animate]="observeIntersection.isIntersecting"
  zvObserveIntersection
  #observeIntersection="observeIntersection"
>
  <a [href]="href" [title]="text"
    ><ng-icon name="featherHash" strokeWidth="1" size="24"></ng-icon> {{ text }}
  </a>
</h2>
