import { Component } from '@angular/core';

@Component({
  selector: 'zv-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  audioNamePronunciation: HTMLAudioElement;

  constructor() {
    this.audioNamePronunciation = new Audio(
      'assets/audio/name-pronunciation-4724f5dedf3de425.mp3'
    );
  }

  playNamePronunciation(): void {
    this.audioNamePronunciation.play();
  }
}
