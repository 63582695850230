import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormCommonInterface } from 'src/app/form/interface/form.interface';

@Component({
  selector: 'zv-text-area[id][label][control]',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent {
  @Input() id!: FormCommonInterface['id'];
  @Input() label!: FormCommonInterface['label'];
  @Input() control!: FormCommonInterface['control'];

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
