<a
  [title]="linkTitle"
  routerLink=""
  (click)="openImageInNewWindow()"
  class="certificate"
  data-test="certificate-link"
>
  <span class="top">
    <ng-icon
      class="badge"
      name="featherAward"
      strokeWidth="1"
      size="28"
      [ngStyle]="{
        transform: 'translateX(5px) translateY(0px)'
      }"
    ></ng-icon>
    <span class="segment left"></span>
    <span class="segment right"></span>
  </span>
  <span class="main">
    <span class="title" data-test="certificate-title">{{ title }}</span>
    <span class="type" data-test="certificate-type">{{ type }}</span>
    <span class="issued-by" data-test="certificate-issued-by">
      by {{ issuedBy
      }}<ng-icon
        name="featherExternalLink"
        strokeWidth="1"
        [ngStyle]="{
          transform: 'translateX(2px) translateY(1px)'
        }"
      ></ng-icon>
    </span>
  </span>
</a>
