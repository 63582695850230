import ConferenceItemInterface from '../../item/interface/conference-item.interface';

const mockedConferenceList: ConferenceItemInterface[] = [
  {
    name: 'Lorem ipsum 2020',
    nameLinkTitle: 'Lorem ipsum 2020',
    nameLinkUrl: '#',
    organizer: 'Numquam earum',
    organizerLinkTitle: 'Numquam earum',
    organizerLinkUrl: '#',
    location: 'Accusamus molestiae eaque fugiat',
    icon: 'featherMonitor',
    role: 'attendee',
    presentationTitle: null,
    note: null,
    isUsedAsMock: true,
    direction: 'left',
  },
  {
    name: 'Dolor Ipsum lorem 2022',
    nameLinkTitle: 'Dolor Ipsum lorem 2022',
    nameLinkUrl: '#',
    organizer: 'Numquam earum',
    organizerLinkTitle: 'Numquam earum',
    organizerLinkUrl: '#',
    location: 'Accusamus molestiae eaque fugiat molestiae',
    icon: 'featherSend',
    role: 'attendee',
    presentationTitle: null,
    note: null,
    isUsedAsMock: true,
    direction: 'right',
  },
  {
    name: 'Lorem dolor ipsum 2022',
    nameLinkTitle: null,
    nameLinkUrl: null,
    organizer: 'Dolor',
    organizerLinkTitle: 'Dolor',
    organizerLinkUrl: '#',
    location: 'Dolor ipsum lorem',
    icon: 'featherSend',
    role: 'speaker',
    presentationTitle: 'Lorem ipsum dolor',
    note: 'Lorem ipsum!',
    isUsedAsMock: true,
    direction: 'left',
  },
];

export default mockedConferenceList;
