<div [ngClass]="direction">
  <div class="conference">
    <p class="role">
      <ng-icon
        [name]="
          role === 'speaker'
            ? 'featherMic'
            : role === 'attendee'
            ? 'featherUser'
            : 'featherUser'
        "
        class="role-icon"
        strokeWidth="1"
        size="24"
      ></ng-icon>
      <span class="role-text">
        <small class="uppercase">{{
          isUsedAsMock ? 'lorem ipsum' : role
        }}</small>
      </span>
    </p>
    <p
      class="role-text-presentation-title"
      *ngIf="role === 'speaker' && presentationTitle"
    >
      {{ presentationTitle }}
    </p>
    <p>
      <zv-external-link
        *ngIf="nameLinkTitle && nameLinkUrl"
        size="p"
        [linkTitle]="nameLinkTitle"
        [linkUrl]="nameLinkUrl"
        >{{ name }}</zv-external-link
      >
      <span *ngIf="!(nameLinkTitle && nameLinkUrl)">{{ name }}</span>
      <span *ngIf="note" class="block"> {{ note }}</span>
    </p>
    <p>
      <small>
        {{ isUsedAsMock ? 'lorem ipsum' : 'Organized by' }}
        <zv-external-link
          *ngIf="organizerLinkTitle && organizerLinkUrl"
          size="small"
          [linkTitle]="organizerLinkTitle"
          [linkUrl]="organizerLinkUrl"
          >{{ organizer }}</zv-external-link
        >
      </small>
    </p>
    <p class="location">
      <span class="location-icon">
        <ng-icon name="{{ icon }}" strokeWidth="1" size="24"></ng-icon>
      </span>
      <span class="location-text">{{ location }}</span>
    </p>
  </div>
</div>
