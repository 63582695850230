<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <zv-input
    id="full-name"
    type="text"
    label="Full name"
    [control]="contactForm.controls.fullName"
  ></zv-input>
  <zv-input
    id="email-address"
    type="email"
    label="Email address"
    [control]="contactForm.controls.emailAddress"
  ></zv-input>
  <zv-input
    id="subject"
    type="text"
    label="Subject"
    [control]="contactForm.controls.subject"
  ></zv-input>
  <zv-text-area
    id="message"
    label="Message"
    [control]="contactForm.controls.message"
  ></zv-text-area>
  <button
    [disabled]="!contactForm.valid"
    type="submit"
    class="px-20 mt-5 mb-2 block"
  >
    Send
  </button>
</form>
