<small
  class="block"
  [class.invisible]="!(control.dirty || control.touched)"
  [class.visible]="control.invalid && (control.dirty || control.touched)"
>
  <ng-container *ngIf="control.errors?.['required']"
    >Please fill in {{ label | lowercase }}.</ng-container
  >
  <ng-container *ngIf="control.errors?.['email']"
    >Please fill in a valid email address.</ng-container
  >
  &nbsp;
</small>
