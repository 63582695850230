<div class="form-group">
  <label [attr.for]="id">{{ label }}</label>
  <div class="relative">
    <textarea
      [id]="id"
      [formControl]="control"
      class="form-control"
      novalidate
    ></textarea>
    <span *ngIf="isRequired()" class="required-sign">*</span>
    <zv-validation [label]="label" [control]="control"></zv-validation>
  </div>
</div>
