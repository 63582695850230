import { Component, Input } from '@angular/core';
import HeadingLinkInterface from 'src/app/heading-link/interface/heading-link.interface';

@Component({
  selector: 'zv-heading-link',
  templateUrl: './heading-link.component.html',
  styleUrls: ['./heading-link.component.scss'],
})
export class HeadingLinkComponent {
  @Input() text!: HeadingLinkInterface['text'];
  @Input() href!: HeadingLinkInterface['href'];
  @Input()
  hasReducedPaddingTop!: HeadingLinkInterface['hasReducedPaddingTop'];
}
