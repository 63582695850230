import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageHomeComponent } from './page/home/home.component';
import { PageStyleGuideComponent } from './page/style-guide/style-guide.component';

const routes: Routes = [
  { path: '', component: PageHomeComponent },
  { path: 'style-guide', component: PageStyleGuideComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // TODO
      // { anchorScrolling: 'enabled' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
