import { Component, Input } from '@angular/core';
import { FormValidationInterface } from 'src/app/form/interface/form.interface';

@Component({
  selector: 'zv-validation[label][control]',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent {
  @Input() label!: FormValidationInterface['label'];
  @Input() control!: FormValidationInterface['control'];
}
