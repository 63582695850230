<div class="hero">
  <div class="md:flex md:flex-row">
    <div class="md:basis-1/12"></div>
    <div class="md:basis-10/12 lg:basis-8/12">
      <h1 class="animate">
        <span class="greeting">
          Hi, my name is
          <span class="name"
            >Žiga.
            <button
              class="plain speaker"
              aria-label="Play name pronunciation"
              (click)="playNamePronunciation()"
            >
              <ng-icon
                name="featherVolume2"
                strokeWidth="1"
                size="28"
              ></ng-icon>
            </button> </span
        ></span>
        <span class="title-container">
          <strong class="title">I am a software engineer</strong>
        </span>
        <span class="specialization">
          specializing in front-end web development.
        </span>
      </h1>
      <div class="lg:flex lg:flex-row">
        <div class="lg:basis-4/5">
          <div class="description">
            <p class="mb-0">
              Located in
              <zv-external-link
                size="p"
                linkTitle="Slovenia"
                linkUrl="https://www.slovenia.info/en"
                >Slovenia</zv-external-link
              >, I focus on creating impactful web applications, driven by a
              dedication to turning ideas into engaging online experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="md:basis-1/12"></div>
  </div>
</div>
